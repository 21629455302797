import { useState, useEffect, useContext, createContext } from "react"
import { Router } from "next/router"
import { useApolloClient } from "@apollo/react-hooks"

import { i18n } from "lib/i18n"
import { FETCH_PAGE } from "lib/graphql"

import slugify from "slugify"
import moment from "moment"

const contentContext = createContext()

export const ContentConsumer = contentContext.Consumer

export const ProvideContent = ({ children, ssrRoute, languages, domain }) => {
  const content = useProvideContent(ssrRoute, languages, domain)

	const onRouteChange = (url) => {
		if (url !== content.route) {
			content.load(url)
		}
	}

	const onLanguageChange = (language) => {
		content.reload(language)
		moment.locale(language)
	}

	useEffect(() => {
		Router.events.on('routeChangeComplete', onRouteChange)
		i18n.on('languageChanged', onLanguageChange)
		return () => {
			Router.events.off('routeChangeComplete', onRouteChange)
			i18n.off('languageChanged', onLanguageChange)
		}
	})

  return (
		<contentContext.Provider value={content}>
			{children}
		</contentContext.Provider>
	)
}

export const useContent = () => {
  return useContext(contentContext)
}

function useProvideContent(baseRoute, languages, baseDomain) {
	const client = useApolloClient()
	let currentPage = null

	let splitted = []
	if (baseRoute) {
		splitted = baseRoute.split('/')
		splitted.shift()
		splitted.shift()
	}
	const [route, setRoute] = useState(`/${splitted.join('/')}`)
	const [domain, setDomain] = useState(baseDomain.domain ? baseDomain : { domain: baseDomain })

	useEffect(() => {
		const slug = route == "/" ? "home" : slugify(route)
		client.query({
			query: FETCH_PAGE,
			variables: { query: `linkRewrite.value:"${slug}"` },
			fetchPolicy: "network-only"
		})
			.then(({data: { cms: { pages: { items } } } }) => {
				if (items.length > 0) {
					setPage(items[0])
				}
			})
	}, [])

	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(currentPage ? {...currentPage, route} : { route, metaTitle: "LaCinetek" })

	const load = async (url, force) => {
		let realUrl = url
		let splitted = url.split('/')
		splitted.shift()
		splitted.shift()
		realUrl = `/${splitted.join('/')}`
		setRoute(realUrl)
		setLoading(true)

		const slug = realUrl == "/" ? "home" : slugify(realUrl)
		let page = await client.query({
			query: FETCH_PAGE,
			variables: { query: `linkRewrite.value:"${slug}"` },
			fetchPolicy: force ? 'network-only' : 'cache-first'
		})

		if (page.data.cms.pages.items.length > 0) page = page.data.cms.pages.items.find(p => p.linkRewrite == slug)
		setPage(page ? {...page, route: realUrl} : {route: realUrl, metaTitle: "LaCinetek"})
		setLoading(false)
	}

	const updateContent = () => {
		reload()
	}

	const reload = async () => {
		load(baseRoute, true)
	}

	const getValue = (key, variables, t) => {
		let value = key
		if (!variables) variables = {}
		if (key instanceof Array) {
			const extLanguage = languages.find(l => l.isoCode == i18n.language)
			value = extLanguage ? key.find(k => k.lang == extLanguage.id).value : key[0].value
		} else {
			const content = (page && page.contents) ? page.contents.find(c => c.key == key) : undefined
			if (content) {
				value = content.content
			} else {
				if (t) value = t(key, variables)
			}
		}

		return value
	}

  return {
		load,
		route,
		reload,
		loading,
		page,
		languages,
		getValue,
		updateContent,
		//FIXME hard coded values
		siteName: "LaCinetek",
		domainName: domain.domain,
		domain,
		protocol: "https"
	}
}
