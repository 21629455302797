import { useEffect, useState } from "react"
import Router from "next/router"

import image, { PRODUCT_PHOTOGRAMME, DIRECTOR_AVATAR } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"
import Image from "components/common/image"
import SkeletonLink from "components/common/skeleton-link"

const MAX_RESULTS = 3

const SearchBar = ({ className, autoCompleteInForm }) => {
	const [query, setQuery] = useState("")
	const [results, setResults] = useState({ products: [], lists: [] })
	const [displayed, setDisplayed] = useState(false)
	const [searching, setSearching] = useState(false)
	const { c } = useTranslation("common")
	const { countrySlug } = useCountry()
	const [typeTimeout, setTypeTimeout] = useState(null)

	const handleSearch = (e) => {
		if (typeTimeout) {
			clearTimeout(typeTimeout)
		}

		const q = e.target.value

		setQuery(q)
		setTypeTimeout(setTimeout(() => {
			if (q !== "") {
				setSearching(true)
				fetch(`/fetch-search/${q}`)
					.then(response => {
						response.json()
							.then(data => {
								setResults(data)
								setDisplayed(true)
								setSearching(false)
							})
					})
			} else {
				setResults({ products: [], lists: [] })
				setDisplayed(false)
			}
		}, 500))
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		setDisplayed(false)

		const product = results.products.slice(0, MAX_RESULTS).find(item => item.selected)

		if (product) {
			Router.push("/[lang]/film/[id]", `/${countrySlug}/film/${product.linkRewrite}`)
			return
		}

		const list = results.lists.slice(0, MAX_RESULTS).find(item => item.selected)

		if (list) {
			Router.push("/[lang]/la-liste-de/[id]", `/${countrySlug}/la-liste-de/${list.linkRewrite}`)
			return
		}

		if (!!query) {
			Router.push("/[lang]/search/[term]", `/${countrySlug}/search/${query}`)
		}
	}

	const handleSearchKeys = (event) => {
		if ([38, 40].indexOf(event.keyCode) === -1) {
			return
		}
		event.preventDefault()

		const items = results.products.slice(0, MAX_RESULTS).concat(results.lists.slice(0, MAX_RESULTS))

		if (items.length === 0) {
			return
		}

		let direction = "forward"

		if (event.keyCode === 38) {
			direction = "backward"
		}

		const selectedIndex = items.findIndex(item => item.selected)

		// Select first if no result is selected and we going forward
		if (selectedIndex === -1 && direction === "forward") {
			items[0].selected = true
		}

		// Select last if no result is selected and we going backward
		if (selectedIndex === -1 && direction === "backward") {
			items[items.length - 1].selected = true
		}

		// Select backward
		if (selectedIndex !== -1 && direction === "backward") {
			delete (items[selectedIndex].selected)
			if (selectedIndex > 0) {
				items[selectedIndex - 1].selected = true
			}
		}

		// Select forward
		if (selectedIndex !== -1 && direction === "forward") {
			delete (items[selectedIndex].selected)
			if (selectedIndex < items.length - 1) {
				items[selectedIndex + 1].selected = true
			}
		}

		setResults({ products: results.products, lists: results.lists })
	}

	useEffect(() => {
		Router.events.on('routeChangeComplete', (url) => setDisplayed(false))
	}, [])

	useEffect(() => {
		if (displayed) {
			window.addEventListener("keydown", handleSearchKeys)

			return () => {
				window.removeEventListener("keydown", handleSearchKeys)
			}
		}

		window.removeEventListener("keydown", handleSearchKeys)
	}, [results, displayed])

	return (
		<>
			<form
				className={`${className ? className : ""} searchbar`}
				aria-label={c("search-input")}
				onSubmit={handleSubmit}
				method="POST"
				role="search"
			>
				<span className="input-container">
					<span className="form-label hidden">
						{c("search-input")}
					</span>
					<input
						type="text"
						name="search-input"
						placeholder={c("search-input")}
						aria-label={c("search-input")}
						onChange={handleSearch}
						autoComplete="off"
					/>
					<button
						type="submit"
						className="submit"
						aria-label={c("validate-my-search")}
					>
						{c("ok")}
					</button>

					{autoCompleteInForm
						&& displayed
						&& (
							<Autocompletion
								query={query}
								products={results.products}
								lists={results.lists}
								handleSubmit={() => { }}
								handleClose={() => setDisplayed(false)}
								c={c}
							/>
						)
					}
				</span>
			</form>

			{!autoCompleteInForm
				&& displayed
				&& (results.products.length || results.lists.length)
				&& (
					<Autocompletion
						query={query}
						products={results.products}
						lists={results.lists}
						handleSubmit={handleSubmit}
						handleClose={() => setDisplayed(false)}
						c={c}
					/>
				)
			}
		</>
	)
}

const Autocompletion = ({ products, lists, c, handleSubmit, handleClose }) => (
	<>
		{!products.length
			&& !lists.length
			? (
				<div className="autocompletion">
					<span className="title">{c("search-no-result")}</span>
				</div>
			)
			: (
				<div className="autocompletion">
					<span className="title">Films <span className="counter">{products.length}</span></span>
					{products.length > 0 && (
						<>
							<ul className="grid min films">
								{products.slice(0, MAX_RESULTS).map((product, index) => (
									<Film product={product} c={c} key={`search-product-${index}`} />
								))}
							</ul>
						</>
					)}

					{lists.length > 0 && (
						<>
							<span className="title">{c("search-results-director")} <span className="counter">{lists.length}</span></span>
							<ul className="grid min directors">
								{lists.slice(0, MAX_RESULTS).map((list, index) => (
									<Director list={list} c={c} key={`search-list-${index}`} />
								))}
							</ul>
						</>
					)}
					<input type="submit" value={c("see-all")} aria-label={c("see-all")} className="button" onClick={handleSubmit} />
					<input type="submit" value={c("close")} aria-label={c("close")} className="button" onClick={handleClose} />
				</div>
			)
		}
	</>
)

const Film = ({ product, c }) => (
	<li className={`item in-selection ${product.selected ? "item-selected" : ""}`}>
		<div className="item-content">
			<SkeletonLink href="/film/[id]" as={`/film/${product.linkRewrite}`}>
				<a title={c("see-single-film")} className="more-link"><span className="label">{c("see-single-film")}</span></a>
			</SkeletonLink>
			<div className="film-link">
				<figure className="cover">
					<picture>
						<Image src={image(product, PRODUCT_PHOTOGRAMME)} alt={product.name} />
					</picture>
				</figure>
				<div className="infos">
					<span className="title">{product.name}</span>
					<span className="director">{c("by")} {product.director}</span>
				</div>
			</div>
		</div>
	</li>
)

const Director = ({ list, c }) => (
	<li className={`item ${list.selected ? "item-selected" : ""}`}>
		<SkeletonLink href="/lists/[id]" as={`/lists/${list.linkRewrite}`}>
			<a title="Voir la fiche film" className="item-content">
				<figure className="avatar">
					<picture>
						<Image src={image(list.director, DIRECTOR_AVATAR)} alt={list.director.name} />
					</picture>
				</figure>
				<div className="infos">
					<h3 className="title-alt">{list.name.replace(" | ", " ")}</h3>
				</div>
			</a>
		</SkeletonLink>
	</li>
)

export default SearchBar
