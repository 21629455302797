import isPrivate from "lib/profile/is-private"
import { getFrom } from "."

export default function hasSvodClass(router) {

	return (
		!isPrivate(router)
		&& !getFrom(router.route).includes("about")
		&& !getFrom(router.route).includes("ways-to-watch")
		&& !getFrom(router.route).includes("offer")
		&& !getFrom(router.route).includes("cart")
		&& !router.route.includes("renew")
	)
}
