import Pass from "static/assets/icons/offers-integrale.svg"
import { useTranslation } from "lib/i18n/translation"
import { useFestival } from "lib/festival"

export default ({festival}) => {

	const { c } = useTranslation("common")
	const { buy, userHasPassFor } = useFestival()

	if (festival.subscriptions.length == 0) return null

	return (
		<section id="festival-offers" style={userHasPassFor(festival) ? {display: "none"} : {}}>
			<h2 className="section-title icon-ticket">{c("buy-festival-pass-strong")}</h2>
			{ festival.subscriptions.map(pass => (
				<>
					<div className="offers single splitted in-half">
						<FestivalPass pass={pass} festival={festival} />
						<div>
							<p>{pass.descriptionShort}</p>
							<p>
								<a href="#" rel="nofollow noopener" title={c("buy-festival-pass")} className="button" onClick={e => { e.preventDefault(); buy(festival, pass) }}>{c("buy-festival-pass")}</a>
							</p>
						</div>
					</div>
				</>
			))}
		</section>
	)
}

export const FestivalPass = ({pass}) => {

	const { c } = useTranslation("common")

	return (
		<>
			<div className="splitted">
				<figure>
					<Pass title={c("festival-pass-icon-title")} />
				</figure>
				<div className="infos">
					<span className="offer-infos">{pass.duration}</span>
					<h3>{pass.name}</h3>
					<p className="price" style={{fontSize: "1.2rem"}}>{pass.description}</p>
				</div>
			</div>
		</>
	)
}
