import { useTranslation } from "lib/i18n/translation"
import { useLanguages } from "lib/languages"

import { Link } from "components/common"

export const LangMenu = () => {
	const { c } = useTranslation("common")
	const { changeLanguage, changing, current } = useLanguages()

	const languages = {
		fr: "french",
		en: "english",
		de: "german"
	}

	return (
		<nav className="lang-selector" aria-label={c("lang-selector")}>
			<ul className="menu">
				<li className="has-submenu">
					<a
						href="#"
						onClick={e => { e.preventDefault(); }}
						rel="nofollow noopener"
						title={c("current-language")}
						className="switch"
						aria-label={c(languages[current])}
					>
						{changing
							? <span className="is-loading"></span>
							: current || "fr"
						}
					</a>
					<ul className="submenu">
						{
							Object.keys(languages).map(country => {
								return (
									<li
										className={(current == country) ? "active hidden" : ""}
										aria-current={(!current || current == country) ? "true" : ""}
										key={`lang-selection-${country}`}
										>
										<Link
											onClick={() => { changeLanguage(country) }}
											title={c(country)}
											target="#"
											rel="nofollow noopener"
											ariaLabel={c(languages[country])}
										/>
									</li>
								)
							})
						}
					</ul>
				</li>
			</ul>
		</nav>
	)
}
