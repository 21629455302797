import React from "react"
import { NextSeo } from "next-seo"
import Head from "./head"

import { useTranslation } from "lib/i18n/translation"
import { isBrowser } from "lib/tools"
import Image from "components/common/image"

const Maintenance = ({route, asPath, localeData}) => {

	const { c } = useTranslation("common")

	if (isBrowser) {
		var body = document.body
		body.classList.add("maintenance")
		body.classList.add("top")
		body.classList.add("end")
	}

	return (
		<>
			<Head route={route} asPath={asPath} localeData={localeData} />
			<NextSeo
				title={c("maintenance-title")}
				description={c("maintenance-meta-desc")}
				keywords={c("maintenance-meta-keywords")}
			/>
			<main>
				<section>
					<div className="infos">
						<h1 className="introduction">{c("maintenance-title")}</h1>
						<p className="excerpt">{c("maintenance-content")}</p>
						<p>{c("maintenance-signature")}</p>
					</div>
				</section>
				<figure className="background">
					<picture>
						<Image src="/static/assets/images/maintenance.jpg" alt={c("maintenance-meta-keywords")} />
					</picture>
				</figure>
			</main>
		</>
	)
}

export default Maintenance
