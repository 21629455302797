import { useCountry } from "lib/country"
import {
	metadata,
	FEATURE_CINECLUB_DISPO_FR,
	FEATURE_CINECLUB_DISPO_BE,
	FEATURE_CINECLUB_DISPO_LU,
	FEATURE_CINECLUB_DISPO_DE,
	FEATURE_CINECLUB_DISPO_AT
} from "lacinetek-common/src/metadata"

export const isAvailable = (cineclub) => {

	const { country } = useCountry()
	let isAvailable = false

	switch(country.isoCode) {
		case "fr":
			isAvailable = metadata(cineclub, FEATURE_CINECLUB_DISPO_FR)?.toLowerCase() === "oui"
			break
		case "be":
			isAvailable = metadata(cineclub, FEATURE_CINECLUB_DISPO_BE)?.toLowerCase() === "oui"
			break
		case "lu":
			isAvailable = metadata(cineclub, FEATURE_CINECLUB_DISPO_LU)?.toLowerCase() === "oui"
			break
		case "de":
			isAvailable = metadata(cineclub, FEATURE_CINECLUB_DISPO_DE)?.toLowerCase() === "oui"
			break
		case "at":
			isAvailable = metadata(cineclub, FEATURE_CINECLUB_DISPO_AT)?.toLowerCase() === "oui"
			break
	}

	return isAvailable
}
