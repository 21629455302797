import { useEffect, useState } from "react"
import Router, { useRouter } from "next/router"
import Zendesk from "react-zendesk"

import { ProvideVideoPlayer } from "lib/video-player"
import { useLanguages } from "lib/languages"
import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import { i18n } from "lib/i18n"
import isBrowser from "lib/tools/is-browser"
import { useAccesses } from "lib/accesses"
import { isSubscriptionUrl } from "lib/subscription/is-subscription-url"

import Prehome from "components/prehome"
import { Foreigner } from "components/modals/foreigner"
import { ForeignSubscription } from "components/modals/foreign-subscription"
import { HeaderAccount } from "components/profile"
import { Maintenance } from "components/modals/maintenance"

import { GtmInit, Header, HeaderAdmin, Footer, Modal, Toast } from "./"
import { AccessInfo } from "./header/access-info"

let lastScroll = 0

const Layout = ({ prehome, children, inMaintenance }) => {
	const { show } = useModal()
	const { user, hasSubscribed } = useAccesses()
	const [hasStickyClass, setHasStickyClass] = useState(true)
	const {
		inEuro,
		country,
		originCountry,
		purchasable,
		originalCountry,
		originalAccess: userCountryMatching,
		countries,
		accesses: {
			access,
			originalAccess
		}
	} = useCountry()
	const router = useRouter()
	const { current } = useLanguages()
	const [myAccount, setMyAccount] = useState(false)

	const updateViewPort = () => {
		const viewportWidth = document.querySelector("main").offsetWidth
		document.documentElement.style.setProperty("--viewport-width", `${viewportWidth}px`)
	}

	const handleScroll = () => {
		var body = document.body
		if (window.scrollY <= 50) {
			body.classList.add("top")
			body.classList.remove("scrolled")
		} else {
			body.classList.add("scrolled")
			body.classList.remove("top")
		}

		if (window.scrollY > 50) {
			if (window.scrollY < lastScroll) {
				body.classList.add("up")
				body.classList.remove("down")
			} else {
				body.classList.add("down")
				body.classList.remove("up")
			}
		} else {
			body.classList.remove("up")
			body.classList.remove("down")
		}
		lastScroll = window.scrollY
	}

	const manageSvodClass = () => {
		if (
			user
			&& hasSubscribed
			&& isSubscriptionUrl(Router)
		) {
			document.body.classList.add("subscribed")
		} else {
			document.body.classList.remove("subscribed")
		}
	}

	useEffect(() => {
		if (user && inEuro && !originalCountry && hasSubscribed) {
			showForeignSubscription()
		} else if (!purchasable) {
			showForeigner()
		}

		if (
			!userCountryMatching
			&& (countries.find(item => item.isoCode == access.country.toLowerCase())
				|| countries.find(item => item.isoCode == originalAccess.country.toLowerCase()))
		) {
			showForeignAccess()
		}

		if (inMaintenance) {
			show({
				id: "mainteance-modal",
				children: <Maintenance />,
				className: "min red bottom interactive",
				contentClassName: "centered",
				role: "alertdialog"
			})
		}
		manageSvodClass()
		window.addEventListener('scroll', handleScroll)
		updateViewPort()

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		if (user && inEuro && !originalCountry && hasSubscribed && !userCountryMatching) {
			showForeignSubscription()
		} else if (!purchasable) {
			showForeigner()
		}
		manageSvodClass()
		Router.events.on("routeChangeComplete", manageSvodClass)

		return () => {
			Router.events.off("routeChangeComplete", manageSvodClass)
		}
	}, [originalCountry, user])

	useEffect(() => {
		document.body.classList.remove("fr", "en", "de")
		document.body.classList.add(current)

		const htmlLang = country.isoCode
			? country.isoCode.toLowerCase()
				+ (i18n.language
				? "-" + i18n.language.toUpperCase()
				: "")
			: "fr"

		document.documentElement.setAttribute('lang', htmlLang)
		i18n.on('languageChanged', (lng) => {
			const htmlLang = country.isoCode
				? country.isoCode.toLowerCase()
					+ (i18n.language
					? "-" + i18n.language.toUpperCase()
					: "")
				: "fr"

			document.documentElement.setAttribute('lang', htmlLang)
		})
	}, [current])

	useEffect(() => {
		setHasStickyClass(!!router.asPath.match(/cart/))
		setMyAccount(document.body.className.includes("private") && !router.asPath.match(/administration/))
	}, [router])

	const showForeigner = () => {
		if ((router && router.query["reset-password-for"]) || (inEuro && originCountry && country.isoCode == originCountry.isoCode)) return

		show({
			id: "restriction-modal",
			children: <Foreigner />,
			className: "min red top interactive",
			contentClassName: "centered",
			role: "alertdialog"
		})
	}

	const showForeignSubscription = () => {
		if (!router || !router.query["reset-password-for"]) {
			show({
				id: "foreign-subscription-modal",
				children: <ForeignSubscription />,
				className: "min red top interactive",
				contentClassName: "centered",
				role: "alertdialog"
			})
		}
	}

	const showForeignAccess = () => {
		if (!router || !router.query["reset-password-for"]) {
			show({
				id: "foreign-access-modal",
				children: <AccessInfo />,
				className: "min red top interactive",
				contentClassName: "centered",
				role: "alertdialog"
			})
		}
	}

	const ZENDESK_KEY = "web_widget/lacinetekhelp.zendesk.com"
	const zendeskSettings = {
		color: {
			theme: "#d92e41"
		},
		helpCenter: {
			title: "Aide",
			searchPlaceholder: "Comment pouvons-nous vous aider ?"
		},
		webWidget: {
			zIndex: 5000
		}
	}

	const backOffice = isBrowser ? router.asPath.includes("administration") : false

	return (
		<div id="wrapper">
			<ProvideVideoPlayer>
				<GtmInit />
				{
					backOffice
						? <HeaderAdmin />
						: <Header />
				}
				{
					myAccount
					&& <HeaderAccount />
				}
				<main className={`${hasStickyClass ? "has-sticky" : ""}`}>
					{children}
				</main >
				<Footer backOffice={backOffice} />
				<Modal />
				<Toast />
				<Zendesk zendeskKey={ZENDESK_KEY} {...zendeskSettings} onLoaded={() => {
					if (prehome) {
						zE("webWidget", "hide")
					}
				}} />
				<Prehome prehome={prehome} />
			</ProvideVideoPlayer>
		</div>
	)
}

export default Layout
