import Head from "next/head"

import { useCountry } from "lib/country"
import { useLanguages } from "lib/languages"
import { replace, getWithLanguage } from "lib/redirects"

export default ({ route, asPath, localeData }) => {
	const { countries } = useCountry()
	const { languages } = useLanguages()
	const splittedAsPath = asPath.split("/")
	let alternates = []
	let link = ""
	let currentRoute = ""
	let matches = []
	let splittedPath = []

	countries.forEach(c => {
		languages.forEach(l => {
			let variables = []
			currentRoute = `/[lang]${getWithLanguage(route, l.isoCode.toLowerCase())}`
			matches = currentRoute.match(/\[[^\]]+\]/g)
			splittedPath = currentRoute.split("/")
			matches.forEach((match, index) => {
				if (match == "[lang]") {
					variables.push(`${c.isoCode}${l.isoCode.toLowerCase() == c.default_language ? "" : `-${l.isoCode.toLowerCase()}`}`)
				} else if (match.includes("[") && match.includes("]")) {
					if (match == "[id]" && localeData) {
						variables.push(localeData[l.isoCode.toLowerCase()])
					} else {
						variables.push(splittedAsPath[splittedPath.indexOf(match)])
					}
				} else {
					variables.push(splittedPath[index])
				}
			})
			link = replace(currentRoute, ...variables)

			if (["fr", "de"].includes(c.isoCode) && l.isoCode.toLowerCase() == c.isoCode) {
				alternates.push({
					lang: c.isoCode,
					link
				})
			}
			alternates.push({
				lang: `${l.isoCode.toLowerCase()}-${c.isoCode.toUpperCase()}`,
				link
			})
		})
	})

	return (
		<Head>
			<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1" />
			<link rel="shortcut icon" sizes="16x16" href="/static/assets/favicon.ico" />
			<link rel="shortcut icon" sizes="32x32" href="/static/assets/favicon-32x32.ico" />
			<link rel="shortcut icon" sizes="48x48" href="/static/assets/favicon-48x48.ico" />

			<link rel="mask-icon" href="/static/assets/images/pinned-tab-icon.svg" />

			<link rel="apple-touch-icon" sizes="57x57" href="/static/assets/apple-icon-57x57.png" />
			<link rel="apple-touch-icon" sizes="60x60" href="/static/assets/apple-icon-60x60.png" />
			<link rel="apple-touch-icon" sizes="72x72" href="/static/assets/apple-icon-72x72.png" />
			<link rel="apple-touch-icon" sizes="76x76" href="/static/assets/apple-icon-76x76.png" />
			<link rel="apple-touch-icon" sizes="114x114" href="/static/assets/apple-icon-114x114.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/static/assets/apple-icon-120x120.png" />
			<link rel="apple-touch-icon" sizes="144x144" href="/static/assets/apple-icon-144x144.png" />
			<link rel="apple-touch-icon" sizes="152x152" href="/static/assets/apple-icon-152x152.png" />
			<link rel="apple-touch-icon" sizes="180x180" href="/static/assets/apple-icon-180x180.png" />
			<link rel="icon" type="image/png" sizes="192x192"  href="/static/assets/android-icon-192x192.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/static/assets/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="96x96" href="/static/assets/favicon-96x96.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/static/assets/favicon-16x16.png" />

			<link rel="manifest" href="/static/assets/manifest.json" />

			<meta name="msapplication-TileColor" content="#ffffff" />
			<meta name="msapplication-TileImage" content="/static/assets/ms-icon-144x144.png" />
			<meta name="theme-color" content="#ffffff" />
			<meta name="facebook-domain-verification" content="p6m6gwu8qr6fjkids3gpxeon95f15n" />

			{ alternates.map(({lang, link}) => (
				<link key={`lang-${lang}`} rel="alternate" hrefLang={`${lang}`} href={`https://www.lacinetek.com${link}`} />
			))}
			<link rel="alternate" hrefLang="x-default" href={`https://www.lacinetek.com${alternates.find(a => a.lang == "en-FR").link}`} />

		  <script
				src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
				type="text/javascript"
				charSet="UTF-8"
				data-domain-script="25266b42-c815-4c82-8e0f-1fa7a12b3795-test"
			>
			</script>
			<script type="text/javascript">
				{ "function OptanonWrapper() { } "}
			</script>

			<script src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=IntersectionObserver"/>
			<script
					dangerouslySetInnerHTML={{
							__html: `
				window.prehomeButtonClick = function button2Click(event, button, standard, times, maxAge, id, link, timeoutId) {

					if (event) {
						event.preventDefault();
					}

					if (timeoutId) {
						window.clearTimeout(timeoutId)
					}

					const el = document.getElementById("overlay-introduction")
					if (!el) {
						return
					}

					document.cookie = "prehomeSession=1;path=/"
					var d = new Date()
					d.setTime(d.getTime() + Number(maxAge))
					if (standard) {
						document.cookie = "prehome=" + times + ";path=/;expires=" + d.toGMTString()
					} else {
						document.cookie = "prehomeEventId=" + id + ";path=/;expires=" + d.toGMTString()
						document.cookie = "prehomeEvent=" + times + ";path=/;expires=" + d.toGMTString()
					}

					if (button === 2) {
						window.location = window.location.origin + link.replace(/~/g, '/')
					} else {
						el.parentNode.removeChild(el)
					}

					if (typeof zE !== "undefined") {
						zE("webWidget", "show")
					} else {
						setTimeout(function() {
							if (typeof zE !== "undefined") {
								zE("webWidget", "show")
							}
						}, 5000)
					}
				}
				`
			}}/>
		</Head>
	)
}
