import { FestivalPass } from "../festival/buy"
import { useTranslation } from "lib/i18n/translation"
import { useCart } from "lib/cart"

export default ({festival}) => {

	const { c } = useTranslation("common")
	const { addProductToCart, adding } = useCart()
	
	const currentPass = festival.subscriptions[0]

	return (
		<>
			{ festival.subscriptions.map(pass => (
					<section>
						<div className="offers single">
							<FestivalPass pass={pass} festival={festival} />
						</div>
					</section>
			))}
			<div className="centered">
				<a href="#" onClick={e => { e.preventDefault(); addProductToCart(Object.assign({}, currentPass, { accessModes: 0 })) }} rel="nofollow noopener" title={c("add-to-my-cart")} className={`button has-price ${(adding ? "is-loading" : "")} "disabled"`}>
					{c("buy-festival-pass")}
				</a>
			</div>
		</>
	)
}
