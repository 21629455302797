import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import { useAuth } from "lib/auth"
import SkeletonLink from "components/common/skeleton-link"

export const Foreigner = ({ }) => {
	const { c } = useTranslation("common")
	const { back } = useModal()
	const { country, originCountry, inEuro } = useCountry()
	const { user, showLogin } = useAuth()

	return (
		<>
			<div className="splitted alt">
				<p>
					{c(`restriction-${inEuro ? "euro-" : ""}first`, { country: ` ${c(country.name)}`, originCountry: originCountry ? ` (${c(originCountry.name)})` : "" })} {c(`restriction-${inEuro ? "euro-" : ""}second`)}{inEuro ? " : " : ""}
					{inEuro
						? <>
							<br />
							{user
								? <SkeletonLink href="/profile"><a>{c("profile")} > {c("my-cinetek")} > {c("month-selection")}</a></SkeletonLink>
								: <a href="#" onClick={e => { e.preventDefault(); showLogin() }} rel="nofollow noopener">{c("profile")} > {c("my-cinetek")} > {c("month-selection")}</a>
							}
						</>
						: null
					}
				</p>
				<p>
					<a
						href="#"
						onClick={e => { e.preventDefault(); back() }}
						rel="nofollow noopener"
						title="OK"
						className="button small close icon-check"
					>
						OK
					</a>
				</p>
			</div>
		</>
	)
}
