import { Query } from "components/common"
import { CINECLUB } from "lib/tools/categories-list"
import { isAvailable } from "lib/tools/availability"
import { FETCH_CINECLUB, FETCH_CINECLUB_MAIN_CATEGORY } from "lib/graphql/queries"
import { metadata } from "lacinetek-common/src/metadata"

export default ({ children, renderLoading }) => (
	<Query
		query={FETCH_CINECLUB}
		options={{ variables: { id: process.env.CATEGORIES.find(c => c.key == CINECLUB).id } }}
		renderLoading={renderLoading}
	>
		{({ cms: { categories: { items: [cineclub] } } }) => {
			const availableCineClub = cineclub.children.items
				.filter(cc => isAvailable(cc) && metadata(cc, "cacher en production") !== "oui")

			return children(availableCineClub)
		}}
	</Query>
)

export const MainCategory = ({ children, renderLoading }) => (
	<Query
		query={FETCH_CINECLUB_MAIN_CATEGORY}
		options={{ variables: { id: process.env.CATEGORIES.find(c => c.key == CINECLUB).id } }}
		renderLoading={renderLoading}
	>
		{({ cms: { categories: { items: [cineclub] } } }) => {
			return children({
				active: metadata(cineclub, "cacher en production") !== "oui",
				main: cineclub
			})
		}}
	</Query>
)
