import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

import Newsletter from "components/modals/newsletter"
import TwiterLogo from "static/assets/images/twitter.svg"

export const SocialBar = () => {

	const { c } = useTranslation()
	const { show } = useModal()
	const { country: { socialNetworks } } = useCountry()

	const showNewsletter = () => {
		show({
			id: "newsletter",
			children: <Newsletter />,
			className: "modal short"
		})
	}

	return (
		<nav className="socialbar" aria-label={c("follow-lacinetek")}>
			{ socialNetworks.map((socialNetwork, index) => (
				<a
					key={`social-network-${index}`}
					href={socialNetwork.url}
					target="_blank"
					title={c(`follow-${socialNetwork.slug}`)}
					className={socialNetwork.slug}
					aria-label={`${c("to-external-site")} ${c(socialNetwork.slug)}`}
					rel="nofollow noopener"
				>
					<span className="hidden">{c("external-link")}</span>
					<span className="label">{c(socialNetwork.slug)}</span>
				</a>
			))}
			<a
				href="#"
				onClick={e => { e.preventDefault(); showNewsletter() }}
				rel="nofollow noopener"
				title={c("register-newsletter")}
				className="newsletter button small"
			>
				{c("register-newsletter")}
			</a>
		</nav>
	)
}
