import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"

export const Maintenance = () => {
	const { back } = useModal()
	const { c } = useTranslation()

	return (
		<>
			<p
			>{c("maintenance-warning")}
			</p>
			<p>
				<a
					href="#"
					onClick={e => { e.preventDefault(); back() }}
					rel="nofollow noopener"
					title="OK"
					className="button small close icon-check"
				>
					OK
				</a>
			</p>
		</>
	)
}
