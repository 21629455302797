import isBrowser from "lib/tools/is-browser"
import { useTranslation } from "lib/i18n/translation"

export default ({}) => {
	
	const { c } = useTranslation("common")

	let title, url
	if (isBrowser) {
		title = document.title
		url = document.location.href
	}
	return (
		<>
			<h2 className="overlay-title">{c("share-content")}</h2>
			<nav className="sharebar" aria-label={c("share-on-social-networks")}>
				<span>{c("share")}</span>
				<a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" title={c("share-on-facebook")} className="facebook" aria-label={`${c("to-external-site")} ${c("share-on-facebook")}`} rel="nofollow noopener">
					<span className="hidden">{c("external-link")}</span>
					<span className="label">{c("facebook")}</span>
				</a>
				<a href={`http://twitter.com/share?text=${title}&url=${url}&hashtags=lacinetek`} target="_blank" title={c("share-on-twitter")} className="twitter" aria-label={`${c("to-external-site")} ${c("share-on-twitter")}`} rel="nofollow noopener">
					<span className="hidden">{c("external-link")}</span>
					<span className="label">{c("twitter")}</span>
				</a>
			</nav>
		</>
	)
}
