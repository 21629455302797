import { useRouter } from "next/router"
import { useQuery } from "@apollo/react-hooks"
import classNames from "classnames"

import { useTranslation } from "lib/i18n/translation"
import { sortLists } from "lib/tools/categories"
import { getFrom } from "lib/redirects"
import { useModal } from "lib/modal"
import { useMainCategories } from "lib/tools/categories"
import { FETCH_MONTH_LIST } from "lib/graphql"
import fullname from "lib/tools/name"

import { Link } from "components/common"
import SkeletonLink from "components/common/skeleton-link"


const ListsOf = ({}) => {
	const router = useRouter()
	const modal = useModal()
	const { c } = useTranslation("common")
	const currentRoute = getFrom(router ? router.route : "/")

	const show = () => {
		modal.show({
			id: "directors",
			children: <List c={c} hide={modal.hide} modal={modal} />,
			className: 'red'
		})
	}

	return (
		<Link
			target="#"
			rel="nofollow noopener"
			title="lists-of"
			aTitle={c("lists-of")}
			className={classNames("icon-chair", { active: currentRoute.includes("/list") })}
			onClick={show}
		/>
	)
}

export const List = ({c, modal}) => {
	const { lists, lifeLists } = useMainCategories()

	const { loading, data } = useQuery(FETCH_MONTH_LIST)
	let monthListId
	if (!loading) {
		const monthListCat = data.cms.topMenuWidget.elements.items.find(i => i.targetType == "CATEGORY")
		if (monthListCat) {
			monthListId = monthListCat.target.id
		}
	}

	return (
		<>
			<h2 className="section-title icon-chair">{c("the-lists-of-strong")}</h2>
			<p className="excerpt">{c("the-lists-of-description")}</p>
			<ul className="grid list directors">
				{ sortLists(lists.concat(lifeLists)).filter(l => (l.name !== undefined)).map(list => (
					<li key={`menu-list-item-${list.id}`} className={`item ${list.isLifeList ? "passed" : ""} ${list.id == monthListId ? "featured" : ""}`}>
						<SkeletonLink href="/lists/[id]" as={`/lists/${c(list.linkRewrite)}`} >
							<a title={list.name.replace(" | ", " ")} onClick={() => { modal.hide() }} className="title-alt">
								{fullname(list, {multiline: true})}
							</a>
						</SkeletonLink>
					</li>
				))}
			</ul>
		</>
	)
}

export default ListsOf
