import React, { useEffect } from "react"

export const useDraggableMenu = () => {
	const ref = React.useRef()
	const className = "scrollable-x scrollbar-hidden"

	useEffect(() => {
		if (!ref.current) {
			return
		}

		let prevX
		let isMoving = false

		const handleSubMenuStart = (event) => {
			event.preventDefault()
			prevX = event.x
			ref.current.addEventListener("mousemove", moveSubMenu)
			document.addEventListener("mouseup", handleSubMenuStop)
		}

		const handleSubMenuStop = (event) => {
			event.preventDefault()
			ref.current.removeEventListener("mousemove", moveSubMenu)
			document.removeEventListener("mouseup", handleSubMenuStop)
			ref.current.querySelectorAll("a").forEach(item => {
				item.style.pointerEvents = "all"
			})
			isMoving = false
		}

		const moveSubMenu = (event) => {
			if (!isMoving) {
				ref.current.querySelectorAll("a").forEach(item => {
					item.style.pointerEvents = "none"
				})
				isMoving = true
			}
			ref.current.scrollLeft += prevX - event.x
			prevX = event.x
		}

		ref.current.addEventListener("mousedown", handleSubMenuStart)

		return () => {
			ref.current.removeEventListener("mousedown", handleSubMenuStart)
		}
	}, [])

	return {
		className,
		ref
	}
}
