import { useTranslation } from "lib/i18n/translation"
import slugify from "slugify"
import { useRouter } from "next/router"

import { useModal } from "lib/modal"
import { useLanguages } from "lib/languages"
import { get } from "lib/redirects"

import CreatePlaylistModal from "components/modals/create-playlist"

const SubHeader = ({ items }) => {
	const { c, ec } = useTranslation("common")
	const { show } = useModal()
	const { current } = useLanguages()
	const router = useRouter()

	if (!items) return null

	const handleSmoothScroll = () => {
		document.documentElement.style.scrollBehavior = 'smooth'

		let timer
		const handleScrollEnd = () => {
			clearTimeout(timer)
			timer = setTimeout(
				() => {
					document.documentElement.style.scrollBehavior = null
					window.removeEventListener('scroll', handleScrollEnd)
				},
				100
			)
		}

		window.addEventListener('scroll', handleScrollEnd)
	}

	const actions = {
		createPlaylist() {
			show({
				id: "playlist-add",
				className: "modal",
				children: <CreatePlaylistModal />
			})
		},
		showPlaylists() {
			const url = `/[lang]${get("/[lang]/profile/playlists")}`

			if (router.pathname !== url) {
				router.push(url, url.replace("[lang]", current))
				return
			}

			window.scroll({ top: 0, behavior: "smooth" })
		}
	}

	const handleClick = (event, item) => {
		if (item.action) {
			event.preventDefault()
			return actions[item.action]()
		}

		return handleSmoothScroll()
	}

	return (
		<>
			{items.map(item => (
				<a
					key={`subheader-item-${item.title}`}
					href={`#${slugify(c(item.title)).toLowerCase()}`}
					title={c(item.title)}
					className={item.icon}
					onClick={event => handleClick(event, item)}
				>
					{ec(item.title)}
				</a>
			))}
		</>
	)
}

export default SubHeader
