import { useState } from "react"
import { useCountry } from "lib/country"
import { SUBSCRIBE_TO_NEWSLETTER } from "lib/graphql/mutations"
import { useApolloClient } from "@apollo/react-hooks"
import { Form, Input } from "@rocketseat/unform"
import * as Yup from "yup"
import { useTranslation } from "lib/i18n/translation"
import { newsletterSubscriptionDataLayer } from "lib/gtm/interactions"
import { useAuth } from "lib/auth"

const schema = (c) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(c("email_invalid"))
			.required(c("email_required"))
	})
}

export default ({}) => {

	const client = useApolloClient()
	const { c } = useTranslation("common")
	const { country } = useCountry()
	const [subscribing, setSubscribing] = useState(false)
	const [error, setError] = useState(false)
	const [success, setSuccess] = useState(false)
	const { user } = useAuth()

	const handleSubmit = ({email}, {resetForm}) => {
		setSubscribing(true)
		client.mutate({mutation: SUBSCRIBE_TO_NEWSLETTER, variables: { email, country: country.isoCode }, context: { clientName: "admin" }})
			.then(({data: { subscribeToNewsletter: result }}) => {
				setError(!result)
				setSuccess(result)
				setSubscribing(false)
				if (result) {
					newsletterSubscriptionDataLayer()
				}
			})
			.catch(() => {
				setError(true)
				setSubscribing(false)
			})
	}

	return (
		<>
			<h2 className="overlay-title">{c("newsletter-title")}</h2>
			<p>{c("newsletter-desc")}</p>
			<Form schema={schema(c)} onSubmit={handleSubmit} aria-label={c("register-newsletter")}>
				<fieldset>
					<legend className="hidden">{c("newsletter-title")}</legend>
					<span className="form-label hidden">{c("ph_email")}</span>
					<span className="input-container icon-mail">
						<Input type="email" placeholder={c("ph_email")} name="email" className="newsletter-mail" value={user ? user.profile.email : null}/>
					</span>
					{ error
						? <p>{c("unable-to-subscribe")}</p>
						: null
					}
					{ success
						? <p>{c("subscribe-successful")}</p>
						: null
					}
					<button type="submit" aria-label={c("i-valid-and-subscribe")} className={subscribing ? "is-loading" : ""}>{c("i-subscribe")}</button>
				</fieldset>
			</Form>
		</>
	)
}
