import { useEffect, useRef, useState } from "react"
import Router from "next/router"

import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { getFrom } from "lib/redirects"
import { useCountry } from "lib/country"
import { useDraggableMenu } from "lib/menu/draggable-menu"
import { useAccesses } from "lib/accesses"

import { Link } from "components/common"
import PromoCode from "components/modals/promo-code"
import { PlayerLauncher } from "components/common"

import { default as defaultEntries } from "config/profile-menus"
import SubHeader from "./sub-header"

const getCurrentRoute = (router) => {
	return getFrom(router.route).replace("/[lang]", "")
}

const HeaderAccount = () => {
	const navRef = useRef(null)
	const { hasSubscribed, hasAccesses } = useAccesses()
	const { show } = useModal()
	const { c } = useTranslation("common")
	const [currentRoute, setCurrentRoute] = useState(Router ? getCurrentRoute(Router) : "/profile")
	const { purchasable, inEuro, svodAvailable } = useCountry()
	const menuProps = useDraggableMenu()

	const actions = {
		launchPlayer() {
			show({
				id: "player-launcher",
				children: <PlayerLauncher />,
				className: "slide top short"
			})
		},
		showPromoCode() {
			show({
				id: "promo-code-input",
				children: <PromoCode />,
				className: "slide top short"
			})
		}
	}

	let entries = defaultEntries

	if (!(purchasable || (inEuro && hasAccesses))) {
		entries = entries.filter(e => !["launch-player"].includes(e.title))
	}

	if (!hasSubscribed) {
		entries = entries.filter(({ title }) => title !== "my-subscription")
	}

	const childrenEntries = [...entries]
	const children = childrenEntries
		.filter(e => !!e.target)
		.reverse()
		.find(e => {
			return currentRoute.includes(e.target)
		}).children

	useEffect(() => {
		const handleChange = () => {
			setCurrentRoute(getCurrentRoute(Router))
		}

		Router.events.on("routeChangeComplete", handleChange)

		return () => {
			Router.events.off("routeChangeComplete", handleChange)
		}
	}, [])

	return (
		<>
			<nav ref={navRef} className={"contextual sticky"}>
				<nav
					className={`filters large ${menuProps.className}`}
					ref={menuProps.ref}
				>
					{entries.map((e, index) => {
						if (e.action) {
							return (
								<a
									href="#"
									rel="nofollow noopener"
									title={c(e.title)}
									className={`${e.icon} ${currentRoute == e.target ? "active" : ""}`}
									onClick={ev => { ev.preventDefault(); actions[e.action]() }}
									key={`list-action-${index}`}
									{...e.props}
								>
									{c(e.title)}
								</a>
							)
						}

						if(e.target === "/subscription" && !svodAvailable) {
							return null
						}

						return (
							<Link
								key={`pmm-entry-${e.title}`}
								target={e.target}
								title={e.title}
								aTitle={c(e.title)}
								className={`${e.icon} ${currentRoute == e.target ? "active" : ""}`}
							/>
						)
					})}
				</nav>
				{children.length > 1
					? <nav className="filters grey">
						<SubHeader items={children} />
					</nav>
					: null
				}
			</nav>
		</>
	)
}

export default HeaderAccount
