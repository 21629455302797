import React from "react"

import App from "next/app"
import cookies from "next-cookies"

import * as Sentry from "@sentry/node"

import { ApolloProvider } from "@apollo/react-hooks"
import { withApolloClient } from "lib/graphql"

import { ProvideModal } from "lib/modal"
import { ProvideAuth } from "lib/auth"
import { ProvideContent } from "lib/content"
import { ProvideLanguages } from "lib/languages"
import { ProvideCountry } from "lib/country"
import { ProvideAccesses } from "lib/accesses"
import { ProvideBilling } from "lib/billing"
import { ProvideMainCategories } from "lib/tools/categories"
import { ProvideCart } from "lib/cart"
import { ProvideMovies } from "lib/movies"
import { ProvideProductsFilters, ProvideProducts } from "lib/products"
import { ProvideBonus } from "lib/bonus"

import { ProvideFestival } from "lib/festival"

import { i18n, appWithTranslation } from "lib/i18n"

import { Layout, Maintenance } from "components/layout"
import Head from "components/layout/head"
import matchLocale from "lib/redirects/locale-matcher"
import Seo from "components/layout/seo"

import { Router, withRouter } from "next/router"

import { isBrowser } from "lib/tools"

import DataFetcher from "lib/data-fetcher"
import { initFilms, fetchAllFilms, getFilms } from "lib/film-fetcher"
import { getToken } from "lib/graphql/apollo/token"
import { init } from "lib/gtm"
import isPrivate from "lib/profile/is-private"

import "scss/main.scss"

import moment from "moment"
import { ProvideAnchorNav } from "../lib/anchor-nav"
import { ProvideToast } from "../lib/toast"
import hasSvodClass from "lib/redirects/is-svod"
import { getFrom } from "lib/redirects"

moment.locale(i18n.language || "fr")

if (process.env.SENTRY_DSN) {
	Sentry.init({ dsn: process.env.SENTRY_DSN })
}

class MyApp extends App {
	scrollTop = true

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			Object.keys(errorInfo).forEach((key) => {
				scope.setExtra(key, errorInfo[key])
			})

			Sentry.captureException(error)
		})

		super.componentDidCatch(error, errorInfo)
	}

	componentDidMount() {
		init()
		this.manageAutoScrollOnPageLoading()
	}

	manageAutoScrollOnPageLoading() {
		if (isBrowser && this.scrollTop) {
			Router.events.on('routeChangeComplete', () => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'auto'
				});
			})
		}
	}

	render() {
		const {
			Component,
			pageProps,
			apolloClient,
			route,
			asPath,
			languages,
			currentIsoCode,
			countries,
			country,
			userCountry,
			availableCountry,
			access,
			originalAccess,
			router,
			lists,
			catalog,
			inMaintenance,
			prehome,
			resetPasswordToken,
			domain,
			ssrFilmsBase,
			localeData,
			lacinetek_env,
			festivals,
			subscriptions,
			isKinowAdmin
		} = this.props

		if (isBrowser) {
			this.scrollTop = !(getFrom(route).split("/")[1] === "subscription")

			if (isPrivate(router)) {
				document.body.classList.add("private")
			} else {
				document.body.classList.remove("private")
			}

			if (hasSvodClass(router)) {
				document.body.classList.add("is-vod")
			} else {
				document.body.classList.remove("is-vod")
			}
		}

		/* Shitty fix to force current language */
		let { lang } = this.props
		if (!Object.keys(lists).includes(lang)) lang = Object.keys(lists)[0]

		if (inMaintenance && !isKinowAdmin) {
			return (
				<ApolloProvider client={apolloClient}>
					<ProvideAuth resetPasswordToken={resetPasswordToken} inMaintenance={inMaintenance} country={country}>
						<ProvideCountry currentIsoCode={currentIsoCode} countries={countries} country={country} userCountry={userCountry} availableCountry={availableCountry} access={access} originalAccess={originalAccess} lang={lang} asPath={`https://${domain.domain || domain}${asPath}`} env={lacinetek_env} subscriptions={subscriptions}>
							<ProvideLanguages current={lang}>
								<Maintenance route={route} asPath={asPath} localeData={localeData} />
							</ProvideLanguages>
						</ProvideCountry>
					</ProvideAuth>
				</ApolloProvider>
			)
		}

		return (
			<ApolloProvider client={apolloClient}>
				<ProvideModal>
					<ProvideToast>
						<ProvideAuth resetPasswordToken={resetPasswordToken} inMaintenance={inMaintenance}>
							<ProvideCountry currentIsoCode={currentIsoCode} countries={countries} country={country} userCountry={userCountry} availableCountry={availableCountry} access={access} originalAccess={originalAccess} lang={lang} asPath={`https://${domain.domain || domain}${asPath}`} env={lacinetek_env} subscriptions={subscriptions}>
								<ProvideAccesses country={country} countries={countries}>
								<ProvideBilling>
								<ProvideLanguages current={lang}>
									<ProvideContent ssrRoute={(typeof window === 'undefined') ? route : router.route} languages={languages} domain={domain}>
										<ProvideMainCategories lists={lists} lang={lang}>
											<ProvideMovies>
												<ProvideProductsFilters>
													<ProvideProducts lang={lang} fetchAllFilms={fetchAllFilms} initFilms={initFilms} getFilms={getFilms} catalog={catalog} ssrFilmsBase={ssrFilmsBase}>
														<ProvideBonus>
															<ProvideCart subscriptions={subscriptions}>
																<ProvideFestival currents={festivals} env={lacinetek_env}>
																	<ProvideAnchorNav>
																		<Head route={route} asPath={asPath} localeData={localeData} />
																		<Seo domain={domain} />
																		<Layout namespacesRequired={["common"]} prehome={prehome} inMaintenance={inMaintenance}>
																			<Component {...pageProps} />
																		</Layout>
																		<div id="tooltips" />
																	</ProvideAnchorNav>
																</ProvideFestival>
															</ProvideCart>
														</ProvideBonus>
													</ProvideProducts>
												</ProvideProductsFilters>
											</ProvideMovies>
										</ProvideMainCategories>
									</ProvideContent>
								</ProvideLanguages>
								</ProvideBilling>
								</ProvideAccesses>
							</ProvideCountry>
						</ProvideAuth>
					</ProvideToast>
				</ProvideModal>
			</ApolloProvider>
		)
	}
}

MyApp.getInitialProps = async (appContext) => {
	if (!isBrowser) {
		initFilms(appContext.ctx.res.baseData.products)
	}

	const appProps = await App.getInitialProps(appContext)
	const lang = appContext.ctx.res ? (appContext.ctx.res.language || "fr") : (cookies(appContext.ctx)["next-i18next"] || "fr")

	let data = await DataFetcher.fetch(isBrowser ? null : appContext.ctx.res, appContext.ctx.apolloClient, lang, appContext.ctx.country, cookies(appContext.ctx))
	if (!isBrowser) {
		delete appContext.ctx.res.baseData.products
	}

	const route = appContext.ctx.pathname
	const asPath = appContext.ctx.asPath
	const localeData = await matchLocale(asPath, appContext.ctx.apolloClient)
	const isKinowAdmin = appContext.ctx.query.admin && appContext.ctx.query.admin === process.env.MAINTENANCE_ADMIN_AUTH

	return {
		...appProps,
		namespacesRequired: ["common"],
		route,
		asPath,
		...data,
		lang,
		token: getToken(),
		resetPasswordToken: appContext.ctx.query ? appContext.ctx.query.ptoken : null,
		domain: isBrowser ? { domain: window.location.hostname } : appContext.ctx.req.headers.host,
		localeData,
		lacinetek_env: process.env.LACINETEK_ENV,
		isKinowAdmin
	}
}

export default appWithTranslation(withApolloClient(withRouter(MyApp)))
