import { useRouter } from "next/router"
import classNames from "classnames"

import { useAccesses } from "lib/accesses"
import { useTranslation } from "lib/i18n/translation"
import { useDraggableMenu } from "lib/menu/draggable-menu"
import { useFestival } from "lib/festival"
import { useCountry } from "lib/country"
import { getFrom } from "lib/redirects"
import { navigationClicSubscriptionDataLayer, enjoyClicSubscriptionDataLayer } from "lib/gtm/interactions"

import { MainCategory } from "components/common/cineclub"
import { Link } from "components/common"
import SkeletonLink from "components/common/skeleton-link"
import ListsOf from "./lists-of"

export const MainMenu = () => {
	const { c } = useTranslation("common")
	const { currents: festivals } = useFestival()
	const { svodAvailable, country } = useCountry()
	const { hasSubscribed } = useAccesses()
	const router = useRouter()
	const currentRoute = getFrom(router ? router.route : "/")
	const menuProps = useDraggableMenu()

	return (
		<nav id="primary" aria-label={c("main-menu")}>
			<ul className="menu">
				<li
					className={classNames({
						active: (
							(
								currentRoute.includes("/[lang]")
								|| currentRoute.includes("/news")
								|| currentRoute.includes("/lists")
								|| currentRoute.includes("/treasure")
								|| currentRoute.includes("/top-lists")
								|| currentRoute.includes("/top-directors")
								|| currentRoute.includes("/top-directors-films")
								|| currentRoute.includes("/festival")
								|| currentRoute.includes("/cine-club")
							)
							&& !currentRoute.includes("/renew")
							&& !currentRoute.includes("/cart")
						)
					})}
				>
					<SkeletonLink href="/">
						<a
							title={c("header-single")}
							className="icon-ticket tvod"
							onClick={(e) => { navigationClicSubscriptionDataLayer("A l'unité") }}
						>
							{c("header-single")}
						</a>
					</SkeletonLink>
					<div className="submenu in-position">
						<nav {...menuProps} >
							<div>
								<Link
									target="/films"
									title={c("all-films")}
									className={classNames("icon-list", { active: currentRoute.includes("/films") })}
								/>
							</div>
							<div>
								<ListsOf />
							</div>
							<div>
								<Link
									target="/news"
									title={c("news")}
									className={classNames("icon-favorite", { active: currentRoute.includes("/news") })}
								/>
							</div>
							<div>
								<Link
									target="/treasures"
									title={c("treasures")}
									className={classNames("icon-eye", { active: currentRoute.includes("/treasure") })}
								/>
							</div>
							<div>
								<Link
									target="/top-lists"
									title={c("top-lists")}
									className={classNames("icon-list-top", { active: currentRoute.includes("/top-lists") })}
								/>
							</div>
							{festivals.map(festival => (
								<div>
									<SkeletonLink href="/festival/[id]" as={`/festival/${festival.linkRewrite}`}>
										<a
											title={festival.name}
											className={classNames("icon-cine-club", { active: currentRoute.includes("/festival") })}
										>
											{festival.name}
										</a>
									</SkeletonLink>
								</div>
							))}
							<MainCategory>
								{({ active }) => !!active && (
									<div>
										<Link
											target="/cine-club"
											title={c("cine-club")}
											className={classNames("icon-cine-club", { active: currentRoute.includes("/cine-club") })}
										/>
									</div>
								)}
							</MainCategory>
						</nav>
					</div>
				</li>
				{
					svodAvailable
					&& <li
						className={classNames({
							active: (
								currentRoute.includes("/subscription")
								&& !currentRoute.includes("/renew")
								&& !currentRoute.includes("/ways-to-watch")
							)
						})}
					>
						<SkeletonLink href="/subscription">
							<a
								title={c("subscription")}
								className="icon-selection-alt svod"
								onClick={(e) => { navigationClicSubscriptionDataLayer("Abonnement") }}
							>
								{c("subscription")}
							</a>
						</SkeletonLink>
						{
							!hasSubscribed
							&& <div className="submenu">
								<div className="banner">
									<div className="banner-content centered">
										<div className="inline">
											<p>{c(`header-try-subscription-${country.isoCode}`)}</p>
											<SkeletonLink href="/ways-to-watch/subscription">
												<a
													title={c(`header-enjoy-${country.isoCode}`)}
													className="button small"
													onClick={enjoyClicSubscriptionDataLayer}
												>
													{c(`header-enjoy-${country.isoCode}`)}
												</a>
											</SkeletonLink>
										</div>
									</div>
								</div>
							</div>
						}
					</li>
				}
			</ul>
		</nav>
	)
}
