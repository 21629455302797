import Router from "next/router"

import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"
import { useAccesses } from "lib/accesses"

import PromoCode from "components/modals/promo-code"
import { PlayerLauncher } from "components/common"
import { Link } from "components/common"
import SkeletonLink from "components/common/skeleton-link"

export const UserMenu = () => {
	const { c } = useTranslation("common")
	const { signout, isKinowAdmin } = useAuth()
	const { hasSubscribed, hasAccesses } = useAccesses
	const { purchasable, inEuro } = useCountry()
	const { show } = useModal()

	const launchPlayer = (event) => {
		event.preventDefault()
		show({
			id: "player-launcher",
			children: <PlayerLauncher />,
			className: "slide top short"
		})
	}

	const showPromoCode = (event) => {
		event.preventDefault()
		show({
			id: "promo-code-input",
			children: <PromoCode />,
			className: "slide top short"
		})
	}

	return (
		<ul className="menu">
			<li className="has-submenu">
				<a
					href="#"
					onClick={e => { e.preventDefault(); }}
					rel="nofollow noopener"
					title={c("profile")}
					className="icon-profile"
				>
					{c("profile")}
				</a>
				<ul className="submenu">
					<li>
						<Link
							target="/profile"
							title={c("my-films")}
							className="icon-chair"
						/>
					</li>
					{
						hasSubscribed
						&& <li>
							<Link
								target="/subscription"
								title={c("my-subscription")}
								className="icon-selection-alt"
							/>
						</li>
					}
					<li>
						<Link
							target="/profile/playlists"
							title={c("my-playlists")}
							className="icon-playlist"
						/>
					</li>
					<li>
						<Link
							target="/profile/parameters"
							title={c("my-params")}
							className="icon-parameters"
						/>
					</li>
					<li>
						<Link
							target="/profile/billing"
							title={c("my-billing")}
							className="icon-card"
						/>
					</li>
					<li>
						<Link
							target="/profile/sponsor"
							title={c("sponsor-friends")}
							className="icon-user"
						/>
					</li>
					{
						(purchasable || inEuro && hasAccesses)
						&& <li>
							<a
								href="#"
								onClick={launchPlayer}
								rel="nofollow noopener"
								title={c("lauch-install-player")}
								data-overlay="#overlay-player"
								className="icon-play"
							>
								{c("launch-install-player")}
							</a>
						</li>
					}
					<li>
						<a
							href="#"
							onClick={showPromoCode}
							rel="nofollow noopener"
							title={c("apply-promo-code-menu")}
							data-overlay="#overlay-player"
							className="icon-favorite"
						>
							{c("apply-promo-code-menu")}
						</a>
					</li>
					{
						isKinowAdmin
						&& <li>
							<SkeletonLink
								href="/administration/maintenance"
								parameters={Router.query.admin ? `admin=${Router.query.admin}` : ""}
							>
								<a
									title={c("my-admin")}
									className={"icon-parameters"}
								>{c("my-admin")}</a>
							</SkeletonLink>
						</li>
					}
					<li>
						<Link
							target="#"
							rel="nofollow noopener"
							onClick={signout}
							title={c("logout")}
							className="icon-logout"
						/>
					</li>
				</ul>
			</li>
		</ul>
	)
}
