import { useState, useContext, createContext } from "react"
import { useAuth } from "lib/auth"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"

import BuyFestivalPass from "components/modals/buy-festival-pass"
import { CategoryVideoPlayer } from "components/common/category-video-player"

const festivalContext = createContext()

export const FestivalConsumer = festivalContext.Consumer

export const ProvideFestival = ({children, currents, env}) => {
  const festival = useProvideFestival(currents, env)

  return (
		<festivalContext.Provider value={festival}>
			{children}
		</festivalContext.Provider>
	)
}

export const useFestival = () => {
  return useContext(festivalContext)
}

const useProvideFestival = (initialCurrents, env) => {
	const { user, showLogin } = useAuth()
	const { show, hide } = useModal()
	const { c } = useTranslation("common")
	const { country, originalCountry } = useCountry()

	const [currents, setCurrents] = useState((["production", "staging"].includes(env)
		?	initialCurrents.filter(f => f.metadata.find(m => m.name == `festival ${env}`) && f.metadata.find(m => m.name == `festival ${env}`).value == "Oui")
		: initialCurrents).filter(f => f.metadata.find(m => m.name == `dispo ${country.name}`) && f.metadata.find(m => m.name == `dispo ${country.name}`).value.toLowerCase() == "oui")
	)

	const buy = (festival, pass) => {
		if (!originalCountry) {
			show({
				id: "festival-not-available-in-your-country",
				children: () => (
					<>
						<h2 className="overlay-title icon-cinepass">{c("festival-not-available-in-your-country-title")}</h2>
						<p>{c("festival-not-available-in-your-country-desc")}</p>
						<p className="centered"><a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" title="OK" className="button small close icon-check">{c("close")}</a></p>
					</>
				),
				className: "modal"
			})
		} else if (!user) {
			showLogin()
		} else {
			showBuy(festival, pass)
		}
	}

	const showBuy = (festival, pass) => {
		show({
			id: "buy-festival-pass",
			title: c("buy-festival-pass-strong"),
			children: <BuyFestivalPass festival={festival} pass={pass} />,
			className: "modal"
		})
	}

	const watchTeaser = (festival) => {
		show({
			id: `festival-teaser-player-for-${festival.id}`,
			children: <CategoryVideoPlayer category={festival} />,
			className: "modal"
		})
	}

	const userHasPassFor = (festival) => {
		if (!user || !user.accesses) return false

		const userSVOD = user.accesses.items.filter(a => a.type == "SVOD").map(a => a.product.id)
		const passesId = festival.subscriptions.map(s => s.id)
		return userSVOD.filter(us => passesId.includes(us)).length > 0
	}

	return {
		currents,
		buy,
		watchTeaser,
		userHasPassFor
	}
}
