import { useContent } from "lib/content"
import { DefaultSeo, NextSeo } from "next-seo"
import { useCountry } from "lib/country"

export default () => {
	const { countrySlug } = useCountry()
	const { loading, page, siteName, domain, domainName, protocol } = useContent()
	if (loading) return null

	const { metaDescription, metaTitle } = page
	const canonical = `${protocol}://${domain.domain || domainName}/${countrySlug}${page.route == "/" ? "" : page.route}`

	return  (
		<>
			<DefaultSeo
				titleTemplate={(page && page.linkRewrite == "home") ? "%s" : `%s - ${siteName}`}
				canonical={canonical}
				noindex={domain.domain !== "lacinetek.com" && domain.domain !== "www.lacinetek.com"}
				nofollow={domain.domain !== "lacinetek.com" && domain.domain !== "www.lacinetek.com"}
				dangerouslySetAllPagesToNoIndex={domain.domain !== "lacinetek.com" && domain.domain !== "www.lacinetek.com"}
				dangerouslySetAllPagesToNoFollow={domain.domain !== "lacinetek.com" && domain.domain !== "www.lacinetek.com"}
			/>
			{ (metaTitle || metaDescription) && (
					<NextSeo
						title={metaTitle || siteName}
						description={metaDescription}
						openGraph={{
							type: "website",
							title: metaTitle,
							description: metaDescription,
							url: canonical,
							site_name: siteName,
							images: [
								{
									url: `${protocol}://${domainName}/static/assets/images/logo.jpg`,
								}
							]
						}}
					/>
			)}
		</>
	)
}
