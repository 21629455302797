const GtmInit = () => {
	return (
		<noscript>
			<iframe
				src={`https://data.lacinetek.com/ns.html?id=${process.env.GTM_ID}`}
				height={0}
				width={0}
				style={{ display: "none", visibility: "hidden" }}
			>
			</iframe>
		</noscript>
	)
}

export default GtmInit
