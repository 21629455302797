import { createContext, useContext } from "react"

import { useApolloClient } from "@apollo/react-hooks"
import { FETCH_PRODUCTS, FETCH_PRODUCT_DETAILS } from "lib/graphql"

const moviesContext = createContext()

export const MoviesConsumer = moviesContext.Consumer

export const ProvideMovies = ({ children }) => {
	const movies = useProvideMovies()

	return (
		<moviesContext.Provider value={movies}>
			{children}
		</moviesContext.Provider>
	)
}

export const useMovies = () => {
	return useContext(moviesContext)
}

const useProvideMovies = () => {

	const client = useApolloClient()

	const all = (force=false) => {
		return fetchMovies(1, [], force)
	}

	const loadDetails = (movie) => {
		return client.query({query: FETCH_PRODUCT_DETAILS, context: { clientName: "admin" }, variables: { id: parseInt(movie.id) }, fetchPolicy: 'network-only'})
			.then(({data: { product }}) => product)
	}

	const fetchMovies = (page=1, movies=[], force=false) => {
		return client.query({query: FETCH_PRODUCTS, variables: { page }, fetchPolicy: force ? 'network-only' : 'cache-first'})
			.then(({data: { cms: { products: { items, pagination }}}}) => {
				if (pagination.page == pagination.lastPage) {
					return movies.concat(items)
				} else {
					return fetchMoviess(page + 1, movies.concat(items), force)
				}
			})
	}

	return {
		all,
		loadDetails
	}
}
