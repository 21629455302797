import { useCountry } from "lib/country"
import { useTranslation } from "lib/i18n/translation"
import isBrowser from "lib/tools/is-browser"
import { useModal } from "lib/modal"

export const AccessInfo = () => {
	const { c } = useTranslation("common")
	const { back } = useModal()
	const { countries, accesses: { access, originalAccess } } = useCountry()

	const originalUrl = isBrowser ? window.location.href.replace(access.country.toLowerCase(), originalAccess.country.toLowerCase()) : `/${originalAccess.country.toLowerCase()}`
	const userPlatform = countries.find(c => c.isoCode == originalAccess.country.toLowerCase())

	return (
		<div className="access-info">
			<span>{c("connected-on-country")} {c(countries.find(c => c.isoCode == access.country.toLowerCase()).name)}</span>
			<div>
				{
					userPlatform
					&& <a
						href={originalUrl}
						className="button small"
					>
						{c("access-to-country", { country: c(userPlatform.name) })}
					</a>
				}
				&nbsp;&nbsp;
				<a
					href="#"
					rel="nofollow noopener"
					className="button small"
					style={{ marginTop: 0 }}
					onClick={e => { e.preventDefault(); back() }}
				>
					{c("close")}
				</a>
			</div>
		</div>
	)
}
