import { FETCH_LOCALE_LINK } from "lib/graphql"

const regexps = [
	{ type: "products", r: "\/film\/(.*)" },
	{ type: "categories", r: "\/(la-liste-de|director-list)\/(.*)", target: 2 },
	{ type: "categories", r: "\/selection\/(.*)" },
	{ type: "categories", r: "\/(hidden-treasures|tresors-caches|verborgene-schaetze)\/(.*)", target: 2 },
]

export default async (route, client, languages = ["en", "fr", "de"]) => {

	let localeData = {}
	let localeMatches = []
	let current = null
	regexps.some(regex => {
		localeMatches = route.match(new RegExp(regex.r))
		if (localeMatches && localeMatches.length > 0) {
			current = { ...regex, link: localeMatches[regex.target || 1] }
			return true
		}
		return false
	})

	if (current) {
		let link = current.link.split('/').pop()
		if (link !== "[id]" && link !== "" && !link.includes('.js')) {
			return Promise.all(languages.map(lang => {
				return client.query({
					query: FETCH_LOCALE_LINK(current.type, lang),
					variables: { query: `linkRewrite.value: ${link}`},
					context: {
						headers: {
							"accept-language": lang
						},
						clientName: "anonym"
					},
					fetchPolicy: 'network-only'
				})
			}))
				.then(data => {
					data.forEach(({data: { cms }}, index) => {
						localeData[languages[index]] = cms[current.type].items.length > 0 ? cms[current.type].items[0].linkRewrite : route
					})
					return localeData
				})
		} else {
			return new Promise((r, rj) => r(null))
		}
	} else {
		return new Promise((r, rj) => r(null))
	}
}
