import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useCountry } from "lib/country"
import SkeletonLink from "components/common/skeleton-link"

export const ForeignSubscription = ({ }) => {
	const { c } = useTranslation("common")
	const { back } = useModal()
	const { country } = useCountry()

	return (
		<div className="splitted alt">
			<p>
				{c("foreign-subscription-first", { country: c(country.name) })}
				<br />
				{c("foreign-subscription-second")} : <SkeletonLink href="/profile"><a>{c("profile")} > {c("my-cinetek")} > {c("month-selection")}</a></SkeletonLink>
			</p>
			<p>
				<a
					href="#"
					onClick={e => { e.preventDefault(); back() }}
					rel="nofollow noopener"
					title="OK"
					className="button small close icon-check"
				>
					OK
				</a>
			</p>
		</div>
	)
}
