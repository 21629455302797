import { memo } from "react"
import { withRouter } from "next/router"
import { useCookies } from "react-cookie"

import isBrowser from "lib/tools/is-browser"
import { useTranslation } from "lib/i18n/translation"

import Image from "components/common/image"

const debug = require("debug")("lacinetek:prehome")

export default memo(withRouter(({ router, prehome }) => {
	const { c } = useTranslation("common")
	const [cookies, removeCookie] = useCookies(["prehome", "prehomeSession", "prehomeEvent", "prehomeEventId"])

	if (router && router.query["reset-password-for"]) {
		return null
	}

	if (!prehome) {
		if (!cookies.prehomeSession) {
			removeCookie("prehomeEvent")
			removeCookie("prehomeEventId")
		}

		return null
	}

	if (cookies.prehomeSession || !isBrowser) {
		return null
	}

	const { id, images, buttons, duration, times, standard } = prehome
	const { button1, button2 } = buttons
	const maxAge = 31536000000

	let timeoutId
	if (isBrowser) {
		timeoutId = window.setTimeout(() => {
			prehomeButtonClick(event, 1, standard, `${times}`, `${maxAge}`, `${id}`, '~')
		}, duration * 1000)
	}

	let image = images.desktop
	if (typeof document !== "undefined") {
		image = mapImage({ images, width: document.body.clientWidth, height: document.body.clientHeight })
	}
	if (!image) {
		image = images.desktop
	}
	debug("Image", image, cookies.prehomeSession, prehome)

	const buttonsCode = `
		${(!button2 || !button2.label) ? "" : `
			<a
				href="${button2.link}"
				rel="nofollow noopener"
				title="${button2.title}"
				onclick="prehomeButtonClick(event, 2, ${standard}, '${times}', '${maxAge}', '${id}', '${button2.link.replace(/\//g, '~')}', ${timeoutId})"
				className="close"
			>
				${button2.label}
			</a>
		`}
		<a
			href="#"
			rel="nofollow noopener"
			title="${button1.title}"
			onclick="prehomeButtonClick(event, 1, ${standard}, '${times}', '${maxAge}', '${id}', '${button1.link.replace(/\//g, '~')}', ${timeoutId})"
			className="close alt"
		>
			${button1.label}
		</a>
	`

	return (
		<div id="overlays" className="overlays">
			<div id="overlay-introduction" className="overlay freeze intro active">
				<div className="overlay-container">
					<div className="overlay-content">
						<nav className={`buttons is-${buttons.position}`} dangerouslySetInnerHTML={{ __html: buttonsCode }}>
						</nav>
						<figure className="background contain">
							<picture>
								<Image src={image} alt={c("alt-prehome")} />
							</picture>
						</figure>
					</div>
				</div>
			</div>
		</div>
	)
}))

function mapImage({ images, width, height }) {
	const ratio = width / height

	if (width > 1000 && ratio > 16 / 9) {
		return images.desktop
	}

	if (width > 569 && ratio > 1) {
		return images.tablet.landscape
	}

	if (width > 569) {
		return images.tablet.portrait
	}

	if (ratio > 1) {
		return images.mobile.landscape
	}

	return images.mobile.portrait
}
