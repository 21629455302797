import { useEffect } from "react"
import { useRouter } from "next/router"

import isBrowser from "lib/tools/is-browser"
import { useTranslation } from "lib/i18n/translation"
import { useAuth } from "lib/auth"
import { getFrom } from "lib/redirects"

import { Link } from "components/common"
import { LangMenu } from "./header/lang-menu"

const HeaderAdmin = () => {
	const { isKinowAdmin } = useAuth()
	const { c } = useTranslation("common")
	const router = useRouter()
	const currentRoute = getFrom(router ? router.route : "/")

	useEffect(() => {
		if (!isKinowAdmin) {
			if (isBrowser) {
				router.push("/")
			}
		}
	}, [isKinowAdmin])

	return (
		<header>
			<nav id="primary" aria-label={c("main-menu")}>
				<ul className="menu">
					<li><Link target="/" title={c("back-homepage")} className={"icon-left"} /></li>
					<li><Link target="/administration/maintenance" title={c("maintenance")} className={`${currentRoute == "/administration/maintenance" ? "active" : ""}`} /></li>
				</ul>
			</nav>
			<nav className={"nav-user only-desktop"}>
				<LangMenu />
			</nav>
		</header>
	)
}

export default HeaderAdmin
