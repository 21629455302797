import { useRef } from "react"
import classNames from "classnames"

import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"

const Overlays = () => {
	const {
		modals,
		active,
		hide,
		back
	} = useModal()
	const { c } = useTranslation("common")

	const doBack = (e) => {
		e.preventDefault()
		if (active && active.options.onBack) {
			active.options.onBack()
		} else {
			back()
		}
	}

	const doHide = (e) => {
		e.preventDefault()
		if (active && active.options.onHide) {
			active.options.onHide()
		} else {
			hide()
		}
	}

	const clickOverlay = (e) => {
		if (e.target.classList && (e.target.classList.contains("overlay") || e.target.classList.contains("preview"))) {
			doHide(e)
		}
	}

	return (
		<div id="overlays" className="overlays" onClick={clickOverlay}>
			{modals.map(({ status, options }, index) => (
				<Overlay
					key={index}
					status={status}
					{...options}
					doHide={doHide}
					doBack={doBack}
				/>
			))}
			<a
				href="#"
				rel="nofollow noopener"
				title={c("close")}
				className="close clickoutside"
				onClick={(e) => {
					e.preventDefault()
					doHide(e)
				}}
			>
				{c("close")}
			</a>
		</div>
	)
}

export default Overlays

export const Overlay = ({
	status,
	id,
	role = "dialog",
	className,
	contentClassName,
	footerClassName,
	title,
	header,
	fixedHeader,
	children,
	footer,
	style,
	forcedId,
	doHide,
	doBack,
	doNotEncapsulate,
	isPreview // For film overlay
}) => {
	const { c } = useTranslation("common")
	const containerRef = useRef(null)
	const {
		modals
	} = useModal()

	const canGoBack = modals.length > 1
		&& modals.findIndex(({ options }) => options.id === id) > 0
		&& !modals[modals.length - 2].options.doNotEncapsulate
		&& modals.every(({ options }) => options.id !== "cart")

	const globalClassName = isPreview ? "preview" : "overlay"

	return (
		<div
			key={forcedId || `${globalClassName}-${id}`} id={forcedId || `${globalClassName}-${id}`}
			className={classNames(
				{
					[`${globalClassName}`]: !doNotEncapsulate,
					"active": !doNotEncapsulate && status == "visible"
				},
				className
			)}
			style={style || {}}
			ref={containerRef}
		>
			{doNotEncapsulate
				? typeof children === "function"
					? children(containerRef)
					: children
				: (
					<div
						id={`${globalClassName}-container-${id}`}
						className={`${globalClassName}-container`}
						style={id === "add-film-to-cart" ? { minWidth: "60%" } : {}}
					>
						{!isPreview && role === "dialog" && (
							<>
								<a
									href="#"
									rel="nofollow noopener"
									title={c("close")}
									className="close"
									onClick={e => {
										e.preventDefault()
										doHide(e)
									}}
								>
									{c("close")}
								</a>
								{canGoBack && status == "visible" && (
									<a
										href="#"
										rel="nofollow noopener"
										title={c("back")}
										className="overlay-back active"
										onClick={e => {
											e.preventDefault()
											doBack(e)
										}}
									>
										{c("back")}
									</a>
								)}
							</>
						)}
						{header
							? header
							: !!title && (
								<div
									className={classNames(
										`${globalClassName}-header`,
										{
											"fixed": fixedHeader
										}
									)}
								>
									<h2 className={`${globalClassName}-title`}>{title}</h2>
								</div>
							)
						}
						<div
							className={classNames(
								`${globalClassName}-content`,
								contentClassName
							)}
							style={id === "add-film-to-cart" ? { width: "100%" } : {}}
						>
							{isPreview && role === "dialog" && (
								<>
									{canGoBack && status == "visible" && (
										<a
											href="#"
											rel="nofollow noopener"
											title={c("back")}
											className="overlay-back active"
											onClick={e => {
												e.preventDefault()
												doBack(e)
											}}
										>
											{c("back")}
										</a>
									)}
									<a
										href="#"
										rel="nofollow noopener"
										title={c("close")}
										className="preview-reduce"
										onClick={e => {
											e.preventDefault()
											doHide(e)
										}}
									>
										{c("close")}
									</a>
								</>
							)}
							{typeof children === "function"
								? children(containerRef)
								: children
							}
						</div>
						{!!footer && (
							<div
								className={classNames(
									`${globalClassName}-footer`,
									footerClassName
								)}
							>
								{footer}
							</div>
						)}
					</div>
				)
			}
		</div>
	)
}
