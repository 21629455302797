import { useTranslation } from "lib/i18n/translation"
import { useToast } from "../../lib/toast"

const Toast = () => {
	const { c } = useTranslation("common")
	const toast = useToast()

	return (
	<div id="overlays-toast" className="overlays">
		<div id="overlay-message-playlist" className={`overlay min yellow bottom interactive temporary ${toast.status}`}>
			<div className="overlay-container">
				<div className="overlay-content centered">
					<div className="splitted alt vertical-centered">
						<p>{toast.message}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default Toast
